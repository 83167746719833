import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants"
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
/**
 * !!! EXPERIMENTAL !!!
 * 
 * After user successfully resets password auth0 will redirect here where
 * we attempt to relaunch the DL app to bring user back to start of authentication
 * instead of displaying the login page in the external browser they find
 * themselves in as a result of clicking the PW reset link in the email. 
 * 
 * This has been done because iOS users are not being recognised as authenticated
 * when being redirected to DL Eligibility in the externally launched browser and
 * as a result not triggering the deep link back into the app.
 * 
 * No issues in Android.
 * 
 * As always.
 * 
 * @returns the page
 */
export default function PWReset() {

    // const { isAuthenticated, logout } = useAuth0();
    //const navigate = useNavigate();

    useEffect(() => {
        window.location.href = 'dl-test.tmr.qld.gov.au://dlasignup/success=false'//process.env.react_app_deep_link;
        setTimeout(function () {
            // If we're still here after timeout, display system error
            console.log("Failed to launch mobile app!");
            //navigate("/SysError", { replace: true });
        }, 5500);
    }, []);

    return (
        <>
            <h1>Queensland Digital Licence App</h1>
           <Loading />
        </>
    );

}